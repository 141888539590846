/*
 * Main entrypoint for Codecogs Equation Editor plugin
 * Use this plugin in your main CKEditor JS file like:
 * 
 * import Eqneditor5 from 'PATH/TO/DIR/eqneditor5';
 * 
 * ClassicEditor
 *	.create( document.querySelector( '#editor' ), {
 *		plugins: [
 *			Eqneditor5,
 *			...
 * 		],
 * 		toolbar: [
 * 			'eqneditor5Button',
 * 			...
 * 		]
 * 		...
 */

import { Plugin } from '@ckeditor/ckeditor5-core/src';
import { ButtonView, ContextualBalloon, clickOutsideHandler } from '@ckeditor/ckeditor5-ui/src';
import DomEventObserver from '@ckeditor/ckeditor5-engine/src/view/observer/domeventobserver';
import { default as EqnEditorView } from './dialog';
import fxIcon from './fx.svg';

class DoubleClickObserver extends DomEventObserver {
    constructor( view ) {
        super( view );

        this.domEventType = 'dblclick';
    }

    onDomEvent( domEvent ) {
        this.fire( domEvent.type, domEvent );
    }
}

export default class Eqneditor5 extends Plugin {
	static get pluginName() {
		return 'Eqneditor5';
	}

	init() {
		const editor = this.editor;

		var host='latex.codecogs.com';
	  	var http=('https:' == document.location.protocol ? 'https://' : 'http://');

		this._installExtScript(http+host+'/js/eqneditor.api.min.js');
		this._installExtCSS(http+host+'/css/eqneditor_1.css');

        // Create the balloon and the form view.
        this._balloon = this.editor.plugins.get( ContextualBalloon );
        this.eqnView = this._createEqnEditorView();

		// Add the "eqneditor5Button" to feature components.
		editor.ui.componentFactory.add( 'eqneditor5Button', locale => {
			const view = new ButtonView( locale );

			view.set( {
				label: editor.t( 'Eqneditor 5' ),
				icon: fxIcon,
				tooltip: true
			} );

			// Insert a text into the editor after clicking the button.
			this.listenTo( view, 'execute', () => {
				this._initEqnEditor(locale);
			} );

			return view;
		} );
	
		// Listen for double click
		const view = editor.editing.view;
		const viewDocument = view.document;

		view.addObserver( DoubleClickObserver );
		editor.listenTo( viewDocument, 'dblclick', function(e, dat) {
			console.log(dat);
			if (dat.domTarget.nodeName.toLowerCase() == "img") {
				var sName = dat.domTarget.src.match( /(gif|svg)\.image\?(.*)/ );
				if(sName!=null) this._initEqnEditor(null, sName[2]);
			}
		}.bind(this) );
	}

	_installExtScript( url ) {
		var scriptTag = document.createElement('script');
		scriptTag.src = url;
		scriptTag.type = 'text/javascript';
		document.head.appendChild(scriptTag);
	}

	_installExtCSS( url ) {
		var link = document.createElement('link');
		link.rel = "stylesheet";
		link.href = url;
		document.head.appendChild(link);
	}

	_initEqnEditor( locale, inp="" ) {
		this._showUI( locale );

		this.output = new EqEditor.Output('output');
		this.textarea = EqEditor.TextArea.link('latexInput')
			.addOutput(this.output)
			.addHistoryMenu(new EqEditor.History('history'));
	
		
		EqEditor.Toolbar.link('toolbar').addTextArea(this.textarea);

		var nodes = document.getElementById('history').childNodes;
		for(var i=0; i<nodes.length; i++) { nodes[i].style.padding = 'revert'; }

		this.textarea.clear();
        this.textarea.insert(inp.replace(/&space;/g, ' '), inp.length);
	}

	_createEqnEditorView() {
        const editor = this.editor;
        const view = new EqnEditorView( editor.locale );

		this.listenTo( view, 'submit', () => {
			console.log("Inserting equation");
			const viewFragment = this.editor.data.processor.toView( this.output.exportAs('html') );
			const modelFragment = this.editor.data.toModel( viewFragment );
			this.editor.model.insertContent(modelFragment);

			this.textarea.pushToHistory();

            // Hide the form view after submit.
            this._hideUI();
        } );

        // Hide the form view after clicking the "Cancel" button.
        this.listenTo( view, 'cancel', () => {
            this._hideUI();
        } );

        // Hide the form view when clicking outside the balloon.
        clickOutsideHandler( {
            emitter: view,
            activator: () => this._balloon.visibleView === view,
            contextElements: [ this._balloon.view.element ],
            callback: () => this._hideUI()
        } );


        return view;
    }

	_hideUI() {
		// Hack to avoid repeated adding when editor is clicked multiple times
		document.getElementById('history').innerHTML = '';
		document.getElementById('toolbar').innerHTML = '';
		document.getElementById('output').src = '';

		this.textarea.clear();

        this.eqnView.element.reset();

        this._balloon.remove( this.eqnView );

        // Focus the editing view after closing the form view.
        this.editor.editing.view.focus();
    }

	_getBalloonPositionData() {
        const view = this.editor.editing.view;
        const viewDocument = view.document;
        let target = null;

        // Set a target position by converting view selection range to DOM.
        target = () => view.domConverter.viewRangeToDom(
            viewDocument.selection.getFirstRange()
        );

        return {
            target
        };
    }

	_showUI( locale ) {
		// const balloon = this.editor.plugins.get( ContextualBalloon );

		this._balloon.add( {
            view: this.eqnView,
            position: this._getBalloonPositionData()
        } );

		// HACK: Causes jumpy window behaviour, but techinically works
		// TODO: Find out why positioning is initially wrong
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 0);
    }
}
